<template>
  <div class="radio w-full" v-click-outside="onClickOutside">
    <selectListe
        v-if="activeOption && defilePosition === 'top' && !writeOption"
        :option="options"
        :style="{'width': width}"
        class="defileTop"
        @info="retourSearch"
    />

    <selectListe
        v-if="activeOption && defilePosition === 'top' && writeOption"
        :option="options"
        :style="{'width': width}"
        :value="value"
        class="defileTop"
        @info="retourSearch"
    />

    <div
        class="champ flex items-center pl-4 pr-2 cursor-pointer"
        :style="{'background-color': background, 'min-height': '40px', 'height': height}"
        @click="openOption"
    >
      <icon
          v-if="icon !== null"
          :data="icon"
          :height="iconHeight"
          :width="iconHeight"
          class="mr-4 cursor-pointer"
          original
      />
      <div
          v-if="!writeOption && !isMultiple"
          class="mr-2 w-4/5 text-left"
      >
        {{ value }}
      </div>

      <div
          v-if="isMultiple"
          class="mr-2 w-4/5 text-left flex flex-wrap pt-3 pb-1"
      >
        <div
            v-for="(item, index) in selectedItem"
            :key="index"
            class="multiItem p-1 pl-2 pr-2 mr-4 mb-2"
        >
          <span>{{ item }}</span> <span
            class="ml-3 close"
            @click="deleteItem(index)"
        >x</span>
        </div>
      </div>

      <div
          v-if="writeOption"
          class="mr-2 w-4/5"
      >
        <input
            v-model="value"
            type="text"
            class="w-full h-full"
            :placeholder="placeholder"
        >
      </div>

      <div
          class="w-1/5 flex justify-end"
      >
        <icon
            :data="icons.ic_down"
            height="13"
            width="13"
            class="cursor-pointer mr-2"
            original
        />
      </div>
    </div>

    <div class="defil">
      <selectListe
          v-if="activeOption && defilePosition !== 'top' && !writeOption"
          :option="options"
          :style="{'width': width}"
          :size="sizeDefil"
          :first="first"
          @info="retourSearch"
      />
      <selectListe
          v-if="activeOption && defilePosition !== 'top' && writeOption"
          :option="options"
          :style="{'width': width}"
          :value="value"
          :first="first"
          @info="retourSearch"
      />
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
import selectListe from './selectFiltre'
import ic_down from '../../../assets/icons/down.svg'

export default {
  name: 'Index',
  components: {
    selectListe
  },
  props: {
    defilePosition: {
      type: String,
      default: 'bottom'
    },
    option: {
      type: Array,
      default: null
    },
    index: {
      type: Number,
      default: -2
    },
    writeOption: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    sizeDefil: {
      type: String,
      default: null
    },
    valueT: {
      type: String,
      default: 'Autres'
    },
    placeholder: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: '40px'
    },
    first: {
      type: String,
      default: null
    },
    icon: {
      type: Object,
      default: null
    },
    background: {
      type: String,
      default: '#F4F5F7'
    },
    iconHeight: {
      type: String,
      default: '30'
    },
  },

  data () {
    return {
      value: null,
      activeOption: false,
      temoinValue: null,
      icons: {
        ic_down
      },
      selectedItem: []
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.value !== 'Autres' && this.value !== null) {
        this.$emit('info', this.value)
      }
      if (this.value !== this.temoinValue) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.activeOption = true
      }
      if (this.isMultiple && this.selectedItem.length > 0) {
        this.$emit('info', this.selectedItem)
      }
    }
  },

  created () {
    this.options = this.option
    this.value = this.valueT
    this.temoinValue = this.value
    this.selectedItem = this.valueT
  },

  methods: {
    onClickOutside () {
      this.activeOption = false
    },

    openOption () {
      this.activeOption = !this.activeOption
    },

    deleteItem(index) {
      this.options.push(this.selectedItem[index])
      this.selectedItem.splice(index, 1)
    },

    retourSearch (answer) {
      this.selected = answer
      this.value = answer
      this.activeOption = false
      this.position = -1
      this.temoinValue = answer
      if (this.isMultiple){
        this.selectedItem.push(answer)

        for (let item in this.options) {
          if (this.options[item] === answer) {
            this.options.splice(item, 1)
          }
        }
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
.champ{
  border: 1px solid #DCDCE4;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  font-family: $font-default;
  color: #0e0e0e;
}
.svg-fill{
  fill: #fff;
}
.champ:focus-within {
  border: 1px solid $focus-color;
}
.champ:hover {
  border: 1px solid $hover-color;
}
.close:hover {
  color: red;
  font-weight: 500;
}
.multiItem {
  background: #ECECEC;
  border-radius: 4px;
  width: auto;
}
.multiItem:hover {
  background: #cfeffa;
}
.defileTop{
  position: absolute;
  margin-top: -160px;
}
input{
  background-color: transparent;
}

@media screen and (max-width: 700px) {
  .champ{
    height: 49.97px;
    font-size: 16px;
  }
}
</style>
